import * as React from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import PostList from '../components/post/PostList';

// markup
const ContactPage = ({ data }) => {

    const edges = data ? data.allMarkdownRemark.edges : [];

    return (
        <main>
            <Header />
            <PostList title="Blog" edges={edges} />
            <Footer />
        </main>
    );
};

export default ContactPage;

export const pageQuery = graphql`
    query {
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 250)
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        slug
                        title
                        tags
                        description
                        category
                    }
                }
            }
        }
    }
`;
